(function()
{

	var m_currentLoaded =
	{
		js : [],
		pages : []
	};

	et.effect =
	{
		pgout : "fadeOut",
		pgin : "fadeIn",
		speed : "quick"
	};

	/** used by presenter to specify the dependencies */
	et.presenter.prototype.require = function(p_handler)
	{
		if (this.requires)
		{
			console.log("requires = " + this.requires);
			loadDependency(this.requires, 0, p_handler);
			//p_handler.call();
		}
		else
		{
			if (p_handler)
				p_handler.call(this);
		}
	};

	/** load the dependent files*/
	var loadDependency = function(p_dependencies, p_index, p_handler)
	{
		loadPage(p_dependencies[p_index], false, null, function()
		{
			if (p_index == (p_dependencies.length - 1))
			{
				p_handler.call();
			}
			else
			{
				loadDependency(p_dependencies, p_index + 1, p_handler);
			}
		});
	};

	/** initialize and bind the controller, usually called by module. */
	et.controller.prototype.bind = function(p_container)
	{
		var controller = this;
		controller.container = p_container;

		if ("onhashchange" in window)
		{
			// event supported?
			window.onhashchange = (function(p_controller)
			{
				return function()
				{
					pageChanged(window.location.hash.substring(1), p_controller);
				};
			})(controller);
		}
		else
		{
			// event not supported:
			var storedHash = window.location.hash;
			window.setInterval((function(p_controller)
			{
				return function()
				{
					if (window.location.hash != storedHash)
					{
						storedHash = window.location.hash;
						pageChanged(storedHash.substring(1), p_controller);
					}
				};
			})(controller), 100);
		}

		if (window.location.hash)
		{
			pageChanged(window.location.hash.substring(1), controller);
		}
		else
		{
			//landing page
			pageChanged(controller.landing, controller);
		}

	};

	et.controller.prototype.unbind = function()
	{

	};

	/** programmatically navigate to a page*/
	et.controller.prototype.navigate = function(p_page, p_params, p_bookmark, p_bookmarkParams)
	{
		if (p_bookmark)
		{
			et.DefaultPageParams["bm"] = JSON.stringify(
			{
				n : p_bookmark,
				p : p_bookmarkParams
			});
			//et.createUrl(p_bookmark, p_bookmarkParams);
		}

		var isExternal = p_page.match(/^(http|https)/) || p_page.match(/^\//);
		//load default params only if it's internal page navigation
		p_params = isExternal ? p_params : this.loadDefaultPageParams(p_params);

		if (p_page)
		{
			if (isExternal)//external navigation
			{
				window.location = et.createUrl(p_page, p_params);
			}
			else//internal navigation
			{
				var currentHash = window.location.hash.substring(1);
            	var inx = currentHash ? currentHash.indexOf("?") : -1;
                if (inx > -1) {
                	currentHash = currentHash.substr(0, inx);
                }
                
                var pageHash = "#" + et.createUrl(p_page, p_params);
                if(this.pages[currentHash] && this.pages[currentHash].skipHistory)
                {
                	window.location.replace(pageHash);
                }
                else
                {
                	window.location.hash = pageHash;
                }
			}

		}
	};

	et.controller.prototype.clearBookmark = function()
	{
		delete et.DefaultPageParams["bm"];
	};

	/** append the default parameters*/
	et.controller.prototype.loadDefaultPageParams = function(p_params)
	{
		if (p_params)
		{
			for (var p in et.DefaultPageParams)
			{
				p_params[p] = et.DefaultPageParams[p];
			}
		}
		else
		{
			p_params = et.DefaultPageParams;
		}

		return p_params;
	};

	/** method to call when the presenter is done loading the page
	 * params:
	 *   forward - next page to go to
	 *   params - page parameters
	 *   useBookmark - if the bookmark will be used
	 *   clearBookmark - if the bookmark needs to be cleared before navigation
	 */
	et.presenter.prototype.pageDone = function(p_param)
	{
		var bmString = et.DefaultPageParams["bm"];
		var bookmark = bmString ? JSON.parse(bmString) : undefined;
		if (!p_param)
			return;
		if (p_param.clearBookmark)
			this.clearBookmark();
		if (!p_param.forward && p_param.useBookmark && !bookmark)
			return;

		setTimeout((function(pp_page, pp_param, pp_bookmark)
		{
			return function()
			{
				if (pp_param.useBookmark)
				{
					if (!pp_bookmark)
					{
						pp_page.controller.navigate(pp_page.controller.pages[pp_page.pageId].forward[pp_param.forward], pp_param.params);
					}
					else
					{
						pp_page.controller.clearBookmark();
						for (var p in pp_param.params)
						{
							bookmark.p[p] = pp_param.params[p];
						}
						pp_page.controller.navigate(bookmark.n, bookmark.p);
					}
				}
				else
				if (p_param.forward)
				{
					var forward = pp_page.controller.pages[pp_page.pageId].forward[pp_param.forward];
					if (forward)
					{
						pp_page.controller.navigate(forward, pp_param.params);
					}
					else
					{
						pp_page.controller.navigate(pp_param.forward, pp_param.params);
					}
				}
				else
				{
					console.log("WARN: forward not specified.");
				}
			};
		})(this, p_param, bookmark), 0);
		return true;
	};
	
	/** 
	 * This method will reload the current presenter & view. Browser history/bookmark won't
	 * be affected. Can be used to reset all inputs on the page. 
	 */
	et.presenter.prototype.reload = function()
	{
		pageChanged(this.pageId, this.controller);
	};

	/** handle the bookmark change*/
	var pageChanged = function(p_pageId, p_controller)
	{
		//process page change
		var inx = p_pageId ? p_pageId.indexOf("?") : -1;
		var pageParamString;
		if (inx > -1)
		{
			pageParamString = p_pageId.substr(inx + 1);
			p_pageId = p_pageId.substr(0, inx);
		}
		console.log("page id = " + p_pageId);

		if (!p_pageId)
			p_pageId = p_controller.landing;

		var defer = p_controller.pageChanged(p_pageId, p_controller);
		defer.done(function(p_siteId, p_sellerId, p_poolId)
		{
			//use mobile version if it's on mobile device
			var pageObj = p_controller.pages[p_pageId];
			if (et.isMobile())
			{
				pageObj = p_controller.pages[p_pageId + "_m"] || pageObj;
			}

			var page = pageObj.name;
			console.log("page name = " + page + ", prams = " + pageParamString);

			//check if the page requires login, if so then go to login page and save this page into bookmark.
			var loginDeferred = $.Deferred();
			if (pageObj.loginRequired)
			{
				var loginRequireDeferred = p_controller.requiresLogin(p_pageId, et.getPageParameters());
				loginRequireDeferred.done(function(data)
				{
					loginDeferred.resolve(data);
				});
			}
			else
			{
				loginDeferred.resolve();
			}

			loginDeferred.done(function(data)
			{
				if (page)
				{
					loadPage(page, true, p_controller, (function(pp_pageId, pp_page, pp_controller)
					{
						return function()
						{
							var presenter = et.provide(pp_page);
							presenter.controller = pp_controller;
							presenter.pageId = pp_pageId;
							et.onPresenterCreated.fire(presenter);
							presenter.go(pp_controller.container);
							et.onPageChanged.fire(presenter);
						};
					})(p_pageId, page, p_controller));
				}
				else
				if (p_controller.landing)
				{
					loadPage(p_controller.landing, true, p_controller, (function(pp_pageId, pp_controller)
					{
						return function()
						{
							var presenter = et.provide(pp_controller.landing);
							presenter.controller = pp_controller;
							presenter.pageId = pp_pageId;
							et.onPresenterCreated.fire(presenter);
							presenter.go(pp_controller.container);
							et.onPageChanged.fire(presenter);
						};
					})(p_pageId, p_controller, p_onPageDone));
				}
			});
		});
		defer.fail(function()
		{

		});

	};

	var loadPage = function(p_pageId, p_unload, p_controller, p_handler)
	{
		if (p_unload && m_currentLoaded.pages && m_currentLoaded.pages.length > 0)
		{
			//p_controller.container[et.effect.pgout](et.effect.speed, (function(pp_pageId, pp_unload, pp_controller, pp_handler)
			//{
			//	return function()
			//	{
			_loadPage(p_pageId, p_unload, p_handler);
			//pp_controller.container[et.effect.pgin](et.effect.speed);
			//	}
			//})(p_pageId, p_unload, p_controller, p_handler));
		}
		else
		{
			_loadPage(p_pageId, p_unload, p_handler);
		}
	};

	var _loadPage = function(p_pageId, p_unload, p_handler)
	{
		if (p_unload)
		{
			//unload the js first
			for (var i = 0; i < m_currentLoaded.js.length; i++)
			{
				document.getElementsByTagName("head")[0].removeChild(m_currentLoaded.js[i]);
				console.log(m_currentLoaded.js[i].src + " unloaded.");
			}
			m_currentLoaded.js = [];
			for (var i = 0; i < m_currentLoaded.pages.length; i++)
			{

				var pageId = m_currentLoaded.pages[i];
				var idx = pageId.lastIndexOf(".");
				var pkg = et.provide(pageId.slice(0, idx));
				pkg[pageId.slice(idx + 1)] = undefined;
				delete m_currentLoaded.pageId;
				console.log(pageId + " unloaded.");
			}
			m_currentLoaded.pages = [];

		}

		et.debug = et.DefaultPageParams[et.constants.DEBUG] = et.getDebug();
		var tmp = p_pageId.split(".").join("/") + (et.debug ? ".js" : ".js");//".min.js");
		console.log("loading page: " + tmp);
		var js = document.createElement('script');
		js.type = "text/javascript";
		js.src = tmp + "?v=" + et.version;
		if (js.readyState)
		{
			// IE
			js.onreadystatechange = function(pp_pageId, pp_handler)
			{
				return function()
				{
					if (this.readyState == "loaded" || this.readyState == "complete")
					{
						this.onreadystatechange = null;

						console.log(this.src + " loaded.");
						et.provide(pp_pageId).require(pp_handler);
					}
				};
			}(p_pageId, p_handler);
		}
		else
		{
			// Others
			js.onload = function(pp_pageId, pp_handler)
			{
				return function()
				{
					console.log(this.src + " loaded.");
					et.provide(pp_pageId).require(pp_handler);
				};
			}(p_pageId, p_handler);
		}
		document.getElementsByTagName("head")[0].appendChild(js);

		m_currentLoaded.pages.push(p_pageId);
		m_currentLoaded.js.push(js);
	};

})();
